<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>订单管理</template>
      <template v-slot:secondMenu>快递管理</template>
    </breadcrumb-nav>
    <el-card>
      <el-button
        type="primary"
        icon="el-icon-plus"
        style="margin: 10px 0px"
        @click="showDialog"
      >添加
      </el-button>
      <el-table style="width: 100%" border :data="list">
        <el-table-column type="index" label="序号" width="80px" align="center">
        </el-table-column>
        <el-table-column prop="imageUrl" label="图标" width="80px">
          <template slot-scope="{ row }">
            <img :src="row.imageUrl" alt="" style="width: 50px; height: 50px" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称" width="200px">
        </el-table-column>
        <el-table-column prop="code" label="编码">
        </el-table-column>
        <el-table-column prop="prop" label="操作" width="200px">
          <template slot-scope="{row}">
            <el-button
              type="warning"
              icon="el-icon-edit"
              size="mini"
              @click="update(row)"
            >修改</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="del(row)"
            >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.page"
        :page-sizes="[5, 10]"
        :page-size="listQuery.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
    <el-dialog
      :title="form.id ? '修改' : '添加'"
      :visible.sync="dialogFormVisible"
    >
      <el-form style="width: 80%" :model="form" :rules="rules" ref="form">
        <el-form-item label="名称" label-width="100px" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="编码" label-width="100px" prop="code">
          <el-input v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item label="图片" label-width="100px" prop="icon">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :headers="imgUploadHeaders"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrUpdate"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import { addExpressRequest, delExpressRequest, getExpressList, updateExpressRequest } from '../../network/goods'

export default {
  name: 'ExpDelivery',
  components: {
    BreadcrumbNav
  },
  data () {
    return {
      listQuery: {
        page: 1,
        size: 10
      },
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      // 对话框显示与隐藏控制的属性
      dialogFormVisible: false,
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      imageUrl: '',
      // 收集签到规则信息
      form: {
        name: '',
        code: '',
        icon: ''
      },
      // 表单验证规则
      rules: {
        // require:必须要校验字段（前面五角星有关系）  message 提示信息    trigger:用户行为设置（事件的设置:blur、change）
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入编码', trigger: 'blur' }]
      }
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.getList()
  },
  methods: {
    getList () {
      getExpressList(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取列表失败', 'error')
        }
        this.list = result.data.list
        for (const item of this.list) {
          if (item.icon !== '' && !item.icon.includes('http')) {
            item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.icon
          } else {
            item.imageUrl = item.icon
          }
        }
        this.total = result.data.total
      })
    },
    update (row) {
      console.log('update row:', row)
      this.dialogFormVisible = true
      this.form = { ...row }
    },
    del (row) {
      console.log('del row:', row)
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delExpressRequest(row.id).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('删除失败', 'error')
          }
          this.alertMessage('删除成功', 'success')
          this.getList()
        })
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
    },
    showDialog () {
      this.dialogFormVisible = true
      this.form = { name: '', code: '' }
    },
    update2Srv () {
      updateExpressRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改失败', 'error')
        }
        this.alertMessage('修改成功', 'success')
        this.getList()
      })
    },
    add2Srv () {
      addExpressRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加失败', 'error')
        }
        this.alertMessage('添加成功', 'success')
        this.getList()
      })
    },
    addOrUpdate () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        this.dialogFormVisible = false
        if (this.form.id) {
          this.update2Srv()
        } else {
          this.add2Srv()
        }
      })
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getList()
    },
    handleUploadSuccess (res, file) {
      console.log('handleUploadSuccess res:', res)
      this.form.icon = res.data.list[0].mediaId
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.icon
    },
    handleImgRemove (file) {
      this.form.icon = ''
      this.imageUrl = ''
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
